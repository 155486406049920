import React from 'react';
import Tab from '../Tab/Tab';
import './TitleBar.css';

const TitleBar = () => {
    return (
        <div className="window-title-bar">
            <div className="action-buttons">
                <button></button>
                <button></button>
                <button></button>
            </div>
            <Tab />
        </div>
    );
};

export default TitleBar;
import React, { useEffect, useState } from 'react';
import eduData from '../../utils/eduData';
import EducationTimelineCard from '../EducationTimelineCard/EducationTimelineCard';
import Timeline from '../Timeline/Timeline';
import './Education.css';

const Education = () => {
    const [contents, setContents] = useState([]);
    useEffect(() => {
        setContents(eduData);
    }, []);

    return (
        <div className={`education`}>
            <h2>Education</h2>
            <Timeline>
                {
                    contents.map(a =>
                        <div class="tcontainer right">
                            <EducationTimelineCard institute={a.institute} degree={a.degree} year={a.year} result={a.result} key={a.id} />
                        </div>
                    )
                }
            </Timeline>
        </div>
    );
};

export default Education;
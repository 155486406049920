import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPython, faJava, faJsSquare, faPhp, faHtml5, faCss3, faReact, faNodeJs, faLaravel, faGit, faGithub, faDocker, faGitlab } from '@fortawesome/free-brands-svg-icons';

const icons = [
    { name: 'python', icon: faPython },
    { name: 'java', icon: faJava },
    { name: 'javascript', icon: faJsSquare },
    { name: 'php', icon: faPhp },
    { name: 'html', icon: faHtml5 },
    { name: 'css', icon: faCss3 },
    { name: 'reactjs', icon: faReact },
    { name: 'node js', icon: faNodeJs },
    { name: 'laravel', icon: faLaravel },
    { name: 'git', icon: faGit },
    { name: 'github', icon: faGithub },
    { name: 'gitlab', icon: faGitlab },
    { name: 'docker', icon: faDocker }
];

const TagSpan = ({ text }) => {
    const icon = icons.find(i => i.name === text.toLowerCase());
    return (
        <span>
            {
                icon && <FontAwesomeIcon icon={icon.icon} />
            }
            {' '}
            {text}
        </span>
    );
};

export default TagSpan;
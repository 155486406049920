const experienceData = [
    {
        id: 4,
        position: 'Software Engineer',
        company: 'Surbana Jurong Private Limited',
        year: 'April 2023 to Present'
    },
    {
        id: 3,
        position: 'Jr. Software Engineer',
        company: 'Surbana Jurong Private Limited',
        year: 'May 2022 to March 2023'
    },
    {
        id: 2,
        position: 'Software Engineer',
        company: 'Dev Skill',
        year: 'January 2022 to April 2022'
    },
    {
        id: 1,
        position: 'Software Engineer Intern',
        company: 'Dev Skill',
        year: 'November 2021 to January 2022'
    },
];

export default experienceData;
const projects = [
    {
        id: 7,
        title: 'DataImporter',
        description: `ASP.NET Core MVC project to import, organize and export data from Excel files`,
        platform: 'web',
        technologies: ['ASP.NET Core', 'MSSQL', 'Entity Framework Core', 'Worker Services', 'JQuery', 'HTML', 'CSS', 'Bootstrap', 'Docker', 'Amazon Web Service (AWS)', 'reCAPTCHA'],
        languages: ['C#', 'JavaScript'],
        repository: {
            remote: 'GitHub',
            url: 'https://github.com/nfsiam/DataImporter'
        }
    },
    {
        id: 6,
        title: 'Online Bus Ticket Booking System',
        description: `ASP.NET Web API project to manage multi vendor online bus ticket booking`,
        platform: 'web',
        technologies: ['ASP .NET', 'JQuery', 'Chart JS', 'MSSQL', 'HTML', 'CSS', 'Bootstrap'],
        languages: ['C#', 'JavaScript'],
        repository: {
            remote: 'GitHub',
            url: 'https://github.com/nfsiam/OnlineBusTicketBookingSystem'
        }
    },
    {
        id: 5,
        title: 'Online Game Store',
        description: `MVC based web application and a Microservice Implementation`,
        platform: 'web',
        technologies: ['Laravel', 'Node JS', 'Express JS', 'Socket-IO', 'JQuery', 'JWT Auth', 'Chart JS', 'MySQL', 'HTML', 'CSS', 'Bootstrap'],
        languages: ['Php', 'JavaScript'],
        repository: {
            remote: 'GitHub',
            url: 'https://github.com/nfsiam/gamestore-laravel'
        }
    },
    {
        id: 4,
        title: 'Online Game Store',
        description: `MVC based web application based on full JS Stack`,
        platform: 'web',
        technologies: ['Node JS', 'Express JS', 'Socket-IO', 'JQuery', 'Chart JS', 'MySQL', 'HTML', 'CSS', 'Bootstrap'],
        languages: ['JavaScript'],
        repository: {
            remote: 'GitHub',
            url: 'https://github.com/nfsiam/game-store'
        }
    },
    {
        id: 3,
        title: 'NoteFused',
        description: `Web application for Online plain text-based Note sharing-editing, file upload-sharing and URL shortening`,
        platform: 'web',
        technologies: ['Php', 'MySQL', 'HTML', 'CSS', 'JQuery'],
        languages: ['Php', 'JavaScript'],
        repository: {
            remote: 'GitHub',
            url: 'https://github.com/nfsiam/NoteFused'
        }
    },
    {
        id: 2,
        title: 'ResManaged',
        description: `Desktop application for managing a restaurant (online ordering and delivery)`,
        platform: 'desktop',
        technologies: [`Windows Forms (C#)`, `MSSQL`],
        languages: ['C#'],
        repository: {
            remote: 'GitHub',
            url: 'https://github.com/nfsiam/ResManaged-CSharp-Project'
        }
    },
    {
        id: 1,
        title: 'Hospital Management System',
        description: `Desktop application for managing a hospital (Patient Admission-Release, Doctor Appointment, Nurse Assignment, Staff Management)`,
        platform: 'desktop',
        technologies: [`Java Swing`, `MySQL`],
        languages: ['Java'],
        repository: {
            remote: 'GitLab',
            url: 'https://gitlab.com/nfsiam/hospital-management-system'
        }
    },
];

export default projects;
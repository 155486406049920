import React, { useEffect } from 'react';
import './Window.css';
import '../Tab/Tab';
import TitleBar from '../TitleBar/TitleBar';
import AddressBar from '../AddressBar/AddressBar';
import ReactGA from 'react-ga';

ReactGA.initialize(process.env.REACT_APP_GA);

const Window = ({ content }) => {
    ReactGA.pageview(window.location.pathname + window.location.search);

    return (
        <div class="window">
            <TitleBar />
            <AddressBar />
            <div className="window-content">
                <div className="window-container">
                    {content}
                </div>
            </div>
        </div>
    );
};

export default Window;
import React from 'react';
import './ProjectCard.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub, faGitlab } from '@fortawesome/free-brands-svg-icons';
import TagSpan from '../TagSpan/TagSpan';




const ProjectCard = (props) => {
    const { title, description, platform, technologies, languages, repository } = props.project;
    const { remote, url } = repository;
    const handleClick = (url) => {
        window.open(url, '_blank');
    };
    return (
        <div className="project-card">
            <h3>{title}</h3>
            <div className="project-description">
                {description}
            </div>
            <div className="project-card-languages">
                {
                    languages.map(l => <TagSpan text={l} />)
                }
            </div>
            <div className="project-technologies">
                {
                    technologies.map(t => <TagSpan text={t} />)
                }
            </div>
            <div className="project-card-footer">
                <div onClick={() => handleClick(url)}>
                    {
                        remote.toLowerCase() === 'github' && <><FontAwesomeIcon icon={faGithub} /> View on GitHub </>
                    }
                    {
                        remote.toLowerCase() === 'gitlab' && <><FontAwesomeIcon icon={faGitlab} /> View on GitLab </>
                    }
                </div>
            </div>
        </div>
    );
};

export default ProjectCard;
import React, { useEffect, useState } from 'react';
import experienceData from '../../utils/experienceData';
import ExperienceTimelineCard from '../ExperienceTimelineCard/ExperienceTimelineCard';
import Timeline from '../Timeline/Timeline';
import './Experience.css';

const Education = () => {
    const [contents, setContents] = useState([]);
    useEffect(() => {
        setContents(experienceData);
    }, []);

    return (
        <div className={`experience`}>
            <h2>Experience</h2>
            <Timeline>
                {
                    contents.map(a =>
                        <div class="tcontainer right">
                            <ExperienceTimelineCard position={a.position} company={a.company} year={a.year} key={a.id} />
                        </div>
                    )
                }
            </Timeline>
        </div>
    );
};

export default Education;
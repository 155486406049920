import React, { useEffect, useState } from 'react';
import './App.css';
import Window from './components/Window/Window';
import { BrowserRouter as Router, Switch, Route, Redirect, withRouter } from 'react-router-dom';
import Home from './components/Home/Home';
import Portfolio from './components/Portfolio/Portfolio';
import Contact from './components/Contact/Contact';


function App() {

  const [typed, setTyped] = useState(false);

  return (
    <div className="body-container">
      <Router>
        <Switch>
          <Route path="/portfolio">
            <Window content={<Portfolio />} />
          </Route>
          <Route path="/contact">
            <Window content={<Contact />} />
          </Route>
          <Route exact path="/">
            <Window content={<Home typed={typed} setTyped={setTyped} />}></Window>
          </Route>
          <Route path="*">
            <Redirect to="/" />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;

import { faBirthdayCake, faEnvelope, faHome, faTint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import profileData from '../../utils/profileData';
import './Profile.css';

const Profile = () => {
    return (
        <div className="profile">
            <h2>Profile</h2>
            <div className="s-row">
                <div className="s-col1">
                    <span><FontAwesomeIcon icon={faHome} /> </span><span>Address</span>
                </div>
                <div className="s-col2">
                    <span>{profileData.address}</span>
                </div>
            </div>
            <div className="s-row">
                <div className="s-col1">
                    <span><FontAwesomeIcon icon={faEnvelope} /> </span><span>Email</span>
                </div>
                <div className="s-col2">
                    <span><a href={'mailto:' + profileData.email}>{profileData.email}</a></span>
                </div>
            </div>
            <div className="s-row">
                <div className="s-col1">
                    <span> <FontAwesomeIcon icon={faBirthdayCake} /> </span><span>Date of Birth</span>
                </div>
                <div className="s-col2">
                    <span>{profileData.dob}</span>
                </div>
            </div>
            <div className="s-row l-s-row">
                <div className="s-col1">
                    <span><FontAwesomeIcon icon={faTint} /> </span><span>Blood Group</span>
                </div>
                <div className="s-col2">
                    <span>{profileData.blood}</span>
                </div>
            </div>
        </div>
    );
};

export default Profile;
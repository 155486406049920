import React, { useEffect, useState } from 'react';
import './Skills.css';
import skills from '../../utils/skillsData';
import TagSpan from '../TagSpan/TagSpan';

const Skills = () => {

    const [contents, setContents] = useState([]);

    useEffect(() => {
        setContents(skills);
    }, []);

    return (
        <div className="skills">
            <h2>Technical Skills</h2>
            {
                contents.map(skill =>

                    <div className="s-row" key={skill.id}>
                        <div className="s-col1">
                            <span>{skill.category}</span>
                        </div>
                        <div className="s-col2">
                            {
                                skill.values.map(v => <TagSpan text={v} />)
                            }
                        </div>
                    </div>
                )
            }
        </div>
    );
};

export default Skills;
import React, { useRef, useState } from 'react';
import emailjs from 'emailjs-com';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog, faSpinner } from '@fortawesome/free-solid-svg-icons';
import ReCAPTCHA from "react-google-recaptcha";


import './Contact.css';

const Contact = () => {
    const [errName, setErrName] = useState('');
    const [errEmail, setErrEmail] = useState('');
    const [errMessage, setErrMessage] = useState('');

    const [captchaPassed, setCaptchaPassed] = useState(false);

    const [state, setState] = useState('form');

    const conForm =
        <>
            <form className="contact-form" onSubmit={sendEmail} autoComplete="off">
                <div className="row">
                    <div className="row-head">
                        <label>Name</label>
                        <small id="errName">{errName}</small>
                    </div>
                    <div className="row-body">
                        <input type="text" name="name" placeholder="your name" />
                    </div>
                </div>
                <div className="row">
                    <div className="row-head">
                        <label>Email</label>
                        <small id="errEmail">{errEmail}</small>
                    </div>
                    <div className="row-body">
                        <input type="email" name="email" placeholder="your email" />
                    </div>
                </div>
                <div className="row">
                    <div className="row-head">
                        <label>Message</label>
                        <small id="errMessage">{errMessage}</small>
                    </div>
                    <div className="row-body">
                        <textarea name="message" placeholder="your message" />
                    </div>
                </div>
                <div className="row">
                    <ReCAPTCHA
                        sitekey={process.env.REACT_APP_REC}
                        onChange={() => setCaptchaPassed(true)}
                        theme="dark"
                        onExpired={() => {
                            captchaPassed(false);
                        }}
                    />
                </div>
                {
                    captchaPassed &&
                    <div>
                        <input type="submit" value="Send" />
                    </div>
                }
            </form>
        </>;

    const sendingDiv =
        <div className="sending">
            <div>
                <FontAwesomeIcon icon={faCog} spin />
            </div>
            <div>
                Sending...
            </div>
        </div>;
    const sentDiv =
        <div className="sent">
            I have received your message
            <br />
            I will get back to you soon!
        </div>;
    const notSentDiv =
        <div className="sent">
            Something went wrong!
            <button onClick={() => { setState('form'); setCaptchaPassed(false) }}>Retry</button>
        </div>;



    const validate = (e) => {
        let valid = true;
        if (e.target.name.value.trim().length < 1) {
            valid = false;
            setErrName('( please enter your name )');
        }
        else {
            setErrName('');
        }
        if (e.target.email.value.trim().length < 1) {
            valid = false;
            setErrEmail('( please enter your email )');
        }
        else {
            setErrEmail('');
        }

        console.log(e.target.message.value.trim());

        if (e.target.message.value.trim().length < 1) {
            valid = false;
            setErrMessage('( please enter your message )');
        }
        else {
            setErrMessage('');
        }
        return valid;
    }
    function sendEmail(e) {
        e.preventDefault();
        if (validate(e) && captchaPassed) {
            setState('sending');
            emailjs.sendForm(process.env.REACT_APP_SERVICE, process.env.REACT_APP_TEMP, e.target, process.env.REACT_APP_USER)
                .then((result) => {
                    if (result.status == 200) {
                        e.target.reset();
                        setState('sent');
                        setCaptchaPassed(false);
                    } else {
                        setState('notSent');
                        setCaptchaPassed(false);
                    }
                }, (error) => {
                    setState('notSent');
                    setCaptchaPassed(false);
                });
        }
    }
    return (
        <div className="contact">
            {
                state === 'form' && conForm
            }
            {
                state === 'sending' && sendingDiv
            }
            {
                state === 'sent' && sentDiv
            }
            {
                state === 'notSent' && notSentDiv
            }
        </div>
    );
};

export default Contact;
import React, { useEffect, useState } from 'react';
import projects from '../../utils/projectData';
import ProjectCard from '../ProjectCard/ProjectCard';

const Portfolio = () => {
    const [projectList, setProjectList] = useState([]);

    useEffect(() => {
        setProjectList(projects);
    }, []);

    return (
        <div>
            <div>
                {
                    projectList.map(p => <ProjectCard project={p} key={p.id} />)
                }
            </div>
        </div>
    );
};

export default Portfolio;
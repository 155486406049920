import React from 'react';
import './ExperienceTimelineCard.css';

const ExperienceTimelineCard = ({ position, company, year }) => {
    console.log(position);
    return (
        <div class="experience-timeline-card">
            <div>
                {position}
            </div>
            <div>
                {company}
            </div>
            <div>
                {year}
            </div>
        </div>
    );
};

export default ExperienceTimelineCard;
const skills = [
    {
        id: 1,
        category: 'Programming Languages',
        values: ['C', 'C++', 'Java', 'Php', 'JavaScript', 'Python', 'C#']
    },
    {
        id: 2,
        category: 'Frontend Tech',
        values: ['HTML', 'CSS', 'JQuery', 'ReactJS', 'Angular']
    },
    {
        id: 3,
        category: 'Backend Tech',
        values: ['Node JS', 'Express JS', 'Laravel', 'ASP.NET', 'ADO.NET', 'ASP.NET Core', 'Balzor', 'Electron.NET', 'Entity Framework Core', 'Autofac', 'NUnit']
    },
    {
        id: 4,
        category: 'Database',
        values: ['MySQL', 'MSSQL']
    },
    {
        id: 5,
        category: 'Software Design Patterns',
        values: ['Repository', 'Unit of Work', 'Adapter', 'Factory', 'Facade', 'Singleton', 'Builder']
    },
    {
        id: 6,
        category: 'Others',
        values: ['Git', 'GitHub', 'Docker', 'Firebase', 'AWS (EC2, SQS, S3Bucket, Load Balancing']
    }
];

export default skills;
import React from 'react';
import './EducationTimelineCard.css';

const EducationTimelineCard = ({ institute, degree, result, year }) => {
    return (
        <div class="education-timeline-card">
            <div>
                {institute}
            </div>
            <div>
                {degree}
            </div>
            <div>
                {year}
            </div>
            <div>
                {result}
            </div>
        </div>
    );
};

export default EducationTimelineCard;
import React from 'react';
import './Timeline.css';

const Timeline = (props) => {
    const contents = props.contents;
    return (
        <div class="timeline">
            {
                props.children
            }
        </div>
    );
};

export default Timeline;
import React from 'react';
import { useLocation } from 'react-router-dom';
import './AddressBar.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShieldAlt, faDownload } from '@fortawesome/free-solid-svg-icons'



const AddressBar = () => {
    const location = useLocation();
    const handleDownload = () => {
        const url = 'https://drive.google.com/file/d/1P2FqAeRJFd0OXWENL0asTQXOlAZAHTk_/view?usp=sharing';
        window.open(url, "_blank");
    }
    return (
        <div class="address-bar-container">
            <div className="address-bar">
                <div>

                    <FontAwesomeIcon icon={faShieldAlt} />
                    <span>
                        https
                </span>
                ://
                <span>
                        nfsiam.me
                </span>
                    <span>
                        {location.pathname === '/' && ''}
                        {location.pathname !== '/' && location.pathname}
                    </span>
                </div>
                {/* {<input type="text" value={'https://nfssiam' + location.pathname} readOnly="true" />} */}
            </div>
            <div className="address-bar-extension">
                <button onClick={handleDownload}><FontAwesomeIcon icon={faDownload} /> Download CV</button>
                <button onClick={handleDownload}><FontAwesomeIcon icon={faDownload} /> CV</button>
            </div>
        </div>
    );
};

export default AddressBar;
import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import './Tab.css';

const Tab = () => {
    const history = useHistory();

    const [activeClasses, setActiveClasses] = useState(['', '', '']);

    const location = useLocation();

    const handleClick = (path) => {
        if (path === '') {
            setActiveClasses(['active-tab', '', '']);
        }
        else if (path === 'portfolio') {
            setActiveClasses(['', 'active-tab', '']);
        }
        else if (path === 'contact') {
            setActiveClasses(['', '', 'active-tab']);
        }
        history.push('/' + path);
    };
    return (
        <div className="title-tabs">
            <button className={activeClasses[0], location.pathname === '/home' || location.pathname === '/' ? 'active-tab' : ''} onClick={() => handleClick('')}>Home</button>
            <button className={activeClasses[1], location.pathname === '/portfolio' ? 'active-tab' : ''} onClick={() => handleClick('portfolio')}>Portfolio</button>
            <button className={activeClasses[2], location.pathname === '/contact' ? 'active-tab' : ''} onClick={() => handleClick('contact')}>Contact</button>
        </div>
    );
};

export default Tab;
const eduData = [
    {
        id: 3,
        institute: 'American International University-Bangladesh',
        degree: 'B.Sc. in CSE',
        year: '2017-2021',
        result: 'CGPA 3.86 out of 4.00'
    },
    {
        id: 2,
        institute: 'Sherpur Govt. College',
        degree: 'HSC',
        year: '2015',
        result: 'GPA 4.83 out of 5.00'
    },
    {
        id: 1,
        institute: 'Sherpur Govt. Victoria Academy',
        degree: 'SSC',
        year: '2013',
        result: 'GPA 5.00 out of 5.00'
    },
];

export default eduData;
import React from 'react';
import Education from '../Education/Education';
import Experience from '../Experience/Experience';
import Profile from '../Profile/Profile';
import Skills from '../Skills/Skills';
import Typewriter from 'typewriter-effect';


import './Home.css';


const Home = ({ typed, setTyped }) => {
    return (
        <div>
            <div class="home-type">
                {
                    !typed && <Typewriter
                        onInit={(typewriter) => {
                            typewriter.changeDelay(30)
                                .typeString(`<span style="font-size:30px; font-weight:700">Hi<span style="color:red">,</span> I am Nafiz Fuad <span style="color:#ffd23e">Siam</span></span>`)
                                .callFunction(() => {
                                })
                                .pauseFor(1000)
                                .typeString('<br/><br/>')
                                .typeString(`<span style="width:100%;font-size:1rem; text-align:justify;">I am currently employed as a Software Engineer at Surbana Jurong Private Limited. As a tech enthusiast, I have a passion for programming and a keen interest in exploring and harnessing state-of-the-art technologies that are influencing the software engineering sector.</span>`)
                                .pauseFor(1000)
                                .callFunction(() => {
                                    setTyped(true);
                                })
                                .start();
                        }
                        }
                    />
                }
                {
                    typed &&
                    <div>
                        <span style={{ fontSize: '30px', fontWeight: '700' }}>Hi<span style={{ color: 'red' }}>,</span> I am Nafiz Fuad <span style={{ color: '#ffd23e' }}>Siam</span></span>
                        <br /><br />
                        <span style={{ width: '100%', fontSize: '1rem', textAlign: 'justify' }}>I am currently employed as a Software Engineer at Surbana Jurong Private Limited. As a tech enthusiast, I have a passion for programming and a keen interest in exploring and harnessing state-of-the-art technologies that are influencing the software engineering sector.</span>
                    </div>
                }
            </div>
            <>
                <Profile />
                <Experience />
                <Skills />
                <Education />
            </>
        </div>
    );
};

export default Home;